<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div class="sec-title">优惠券列表</div>

    <div class="ontents" style="margin-bottom: 1rem">
      <el-button
        type="warning"
        @click="drow = !drow"
        class="blt"
        plain
        size="small"
        >新增优惠类型</el-button
      >
    </div>
    <div>
      <div class="contentList">
        <div class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
            @selection-change="selectiondrawCode"
          >
            <el-table-column
              prop="name"
              align="center"
              label="优惠类型"
            ></el-table-column>
            <el-table-column
              prop="type"
              align="center"
              label="类型"
            ></el-table-column>
            <el-table-column
              prop="descr"
              align="center"
              label="使用需求"
            ></el-table-column>
            <el-table-column
              prop="status"
              align="center"
              label="状态"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              label="创建时间"
            ></el-table-column>

            <el-table-column prop="status" align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="openUpdate(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  @click="openDelete(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          <paging-fy
            @currentPageChange="handleCurrentChange"
            :currentPage="currentPage"
            :total="total"
          ></paging-fy>
        </div>
      </div>
    </div>
    <div class="a2">
      <el-dialog
        :width="ks ? '95%' : '50%'"
        title="生成优惠类型"
        :visible="drow || updateShow"
      >
        <el-form :model="form">
          <el-form-item label="优惠类型名称" :label-width="formLabelWidth">
            <el-input
              v-model="form.name"
              autocomplete="off"
              :placeholder="temp.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型" :label-width="formLabelWidth">
            <el-input
              v-model="form.type"
              autocomplete="off"
              :placeholder="temp.type"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠类型描述" :label-width="formLabelWidth">
            <el-input
              v-model="form.descr"
              autocomplete="off"
              :placeholder="temp.descr"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="
              drow = false;
              updateShow = false;
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="add" v-if="drow">确 定</el-button>
          <el-button type="primary" v-if="updateShow" @click="update()"
            >修改</el-button
          >
        </div>
      </el-dialog>
      <el-dialog :visible.sync="if_delete">
        <h2>确认删除?</h2>
        <div slot="footer" class="dialog-footer">
          <el-button @click="if_delete = false">取 消</el-button>
          <el-button type="primary" @click="deleteType()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="a1">
      <el-dialog
        title="生成优惠类型"
        :width="ks ? '95%' : '50%'"
        :visible="drow || updateShow"
      >
        <el-form :model="form">
          <el-form-item label="优惠类型名称" :label-width="formLabelWidth">
            <el-input
              v-model="form.name"
              autocomplete="off"
              :placeholder="temp.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型" :label-width="formLabelWidth">
            <el-input
              v-model="form.type"
              autocomplete="off"
              :placeholder="temp.type"
            ></el-input>
          </el-form-item>
          <el-form-item label="优惠类型描述" :label-width="formLabelWidth">
            <el-input
              v-model="form.descr"
              autocomplete="off"
              :placeholder="temp.descr"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="
              drow = false;
              updateShow = false;
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="add" v-if="drow">确 定</el-button>
          <el-button type="primary" v-if="updateShow" @click="update()"
            >修改</el-button
          >
        </div>
      </el-dialog>
      <el-dialog :visible.sync="if_delete">
        <h2>确认删除?</h2>
        <div slot="footer" class="dialog-footer">
          <el-button @click="if_delete = false">取 消</el-button>
          <el-button type="primary" @click="deleteType()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveStreaming",
  components: {},
  data() {
    return {
      nav: {
        firstNav: "优惠券管理",
        secondNav: "优惠券列表",
      },
      drow: false,
      // 、、、
      tableData: [], //总数据
      code: "",
      id: [],
      total: 0,
      cou_type_list: [],
      target: null,
      name: "",
      phone: "",
      couponCode: "",
      sels: [], //选中数据组
      form: {},
      pageSize: 10,
      currentPage: 1,
      total: 0,
      cou_cate_list: [],
      cou_cate_child_list: [],
      cate_id: null,
      formLabelWidth: "120px",
      if_delete: false,
      updateShow: false,
      temp: {},
      ks: "",
    };
  },
  mounted() {
    this.GetList();
    this.ks = window.screen.width < 768;
  },
  methods: {
    add() {
      let _this = this;
      this.form.status = 2;
      let url = "/user/commoditycouponType/insert";
      let data = {
        ...this.form,
      };
      this.$axios.post(url, data).then((res) => {
        console.log(res);
        this.GetList();
      });
      this.drow = !this.drow;
    },

    GetList() {
      let url = "/user/commoditycouponType/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            currentPage: this.currentPage,
            pageSize: 10,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },

    openDelete(row) {
      this.if_delete = true;
      this.target = row;
    },
    openUpdate(row) {
      this.temp = row;
      this.updateShow = true;
    },
    deleteType() {
      let url = "/user/commoditycouponType/update";
      this.$axios
        .post(url, {
          status: 1,
          id: this.target.id,
        })
        .then((res) => {
          console.log(res);
          this.if_delete = false;
          this.GetList();
        });
    },
    update() {
      let url = "/user/commoditycouponType/update";
      this.$axios
        .post(url, {
          id: this.temp.id,
          ...this.form,
        })
        .then((res) => {
          console.log(res);
          this.updateShow = false;
          this.GetList();
        });
    },
    selectiondrawCode(selection) {
      //  console.log(selection,"当列数据111")
      this.sels = selection;
    },

    filterChange(filterObj) {
      //  console.log(filterObj, "当列数据");
    },
    handleCurrentChange(val) {
      //  console.log(`当前页: `, val);
      this.currentPage = val;
      this.GetList();
    },
  },
};
</script>

<style lang="less">
.a2 {
  display: block;
  @media screen and(max-width: 768px) {
    display: none;
  }
}
.a1 {
  display: none;
  @media screen and(max-width: 768px) {
    display: block;
  }
}
</style>
